<!-- Modals -->
<app-bulk-select-company-documents-dialog
    #selectCompanyDocuments
    [allowShowOfferletter]="false"
    (filtersAdded)="onDocumentFiltersAdded()"
></app-bulk-select-company-documents-dialog>

<app-deprecated-modal #pdfPreviewModal tall="true">
    <app-pdf-viewer #pdfViewer></app-pdf-viewer>
</app-deprecated-modal>

<app-deprecated-modal-form
    #multiSignerAssignmentModal
    [form]="multiSignerAssignmentForm"
    [hideOnSave]="false"
    (cancel)="multiSignerAssignmentForm.reset()"
    (save)="onMultiSignerAssign()"
    headerText="forms.applicant-tracker.onboarding-documents.assignEmployees"
>
    <app-form-onboarding-multi-signer-assignment
        #multiSignerAssignmentForm
    ></app-form-onboarding-multi-signer-assignment>
</app-deprecated-modal-form>

<form class="ui form" #form="ngForm" [class.loading]="isLoading">
    <ui-banner *ngIf="form.submitted && allDocumentAssigned.invalid" variant="danger" class="margin-bottom-3">
        <p class="margin-right-1">
            {{
                'forms.applicant-tracker.onboarding-documents.youHaveMultisignDocumentsMissingAssignmentsPleaseAssignEmployees'
                    | appTranslate
            }}
        </p>
    </ui-banner>

    <div class="help-text">
        <ng-container *ngIf="!documentAssignments.length"
            >{{ 'forms.applicant-tracker.onboarding-documents.selectOnboardingDocuments' | appTranslate }}
        </ng-container>
        <ng-container *ngIf="documentAssignments.length && !hasSigningTemplateSelected()"
            >{{ 'forms.applicant-tracker.onboarding-documents.clickOnADocumentToPreview' | appTranslate }}
        </ng-container>
        <ng-container *ngIf="hasSigningTemplateSelected()"
            >{{
                'forms.applicant-tracker.onboarding-documents.clickOnADocumentToPrevieweditPleaseNoteEditsMadeDuringThePreview'
                    | appTranslate
            }}
            <ui-info
                tooltipPosition="above"
                text="forms.applicant-tracker.onboarding-documents.onceSavedEditsCannotBeViewedAndMakingAnotherEditWillS"
            >
            </ui-info>
            <ng-container *ngIf="allowMultiSignDocuments">
                <br />{{
                    'forms.applicant-tracker.onboarding-documents.inOrderToProceedToTheNextStepAllMultisignDocumentsMustHaveAnEmpl'
                        | appTranslate
                }}
            </ng-container>
        </ng-container>
    </div>

    <div class="ui divider"></div>

    <!-- Document Assignments -->
    <div *ngFor="let documentAssignment of documentAssignments" class="app-grid doc-item">
        <ng-container *ngIf="documentAssignment.isMultiSigner() && allowMultiSignDocuments; else single">
            <div class="app-grid">
                <div class="padding-left-5 title columns-8">{{ documentAssignment.companyDocument.name }}</div>
                <div class="status columns-4">
                    <ui-pill
                        type="primary"
                        *ngIf="documentAssignment.isSigningDocument() && documentAssignment.hasTemplateBeenEdited()"
                        >{{ 'forms.applicant-tracker.onboarding-documents.edited' | appTranslate }}
                    </ui-pill>
                    <ui-button
                        [type]="documentAssignment.hasSlotAssignmentsCompleted() ? 'ghost' : 'outline'"
                        (click)="openAssignmentModal(documentAssignment)"
                    >
                        {{
                            {
                                key: 'forms.applicant-tracker.onboarding-documents.actionEmployees',
                                params: {
                                    action: documentAssignment.hasSlotAssignmentsCompleted() ? 'Edit' : 'Assign',
                                },
                            } | appTranslate
                        }}
                    </ui-button>
                    <mat-icon svgIcon="close" (click)="onRemoveDocumentAssignment(documentAssignment)"></mat-icon>
                </div>
            </div>
            <div class="app-grid">
                <div
                    *ngIf="documentAssignment.hasNoSlotAssignmentsCompleted(); else hasSlotAssignments"
                    class="app-grid doc-assignment"
                >
                    <div class="assignee">
                        <mat-icon class="ghost"></mat-icon
                        >{{
                            'forms.applicant-tracker.onboarding-documents.noEmployeesAssignedToThisDocument'
                                | appTranslate
                        }}
                    </div>
                </div>
                <ng-template #hasSlotAssignments>
                    <div
                        *ngFor="let slotAssignment of documentAssignment.assignmentsInOrder()"
                        class="app-grid doc-assignment"
                    >
                        <div class="columns-4 assignee">
                            <mat-icon class="ghost"></mat-icon>
                            <app-avatar [employee]="slotAssignment.employee"></app-avatar>
                            <div>
                                {{
                                    slotAssignment.employee?.fullName ||
                                        ('forms.applicant-tracker.onboarding-documents.noEmployeeAssigned'
                                            | appTranslate)
                                }}
                            </div>
                        </div>

                        <div class="columns-8 signer-role">
                            <span *ngIf="slotAssignment.order"> {{ slotAssignment.order }}. &nbsp; </span>
                            {{ slotAssignment.slot.name | appTranslateOptions: slotDisplayOptions }}
                            <span *ngIf="slotAssignment.order === 1">
                                &nbsp; ({{ 'forms.applicant-tracker.onboarding-documents.firstToSign' | appTranslate }})
                            </span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>

        <ng-template #single>
            <div class="title columns-8" (click)="onDocumentPreview(documentAssignment)">
                <mat-icon [svgIcon]="documentAssignment.isSigningDocument() ? 'edit' : 'visibilityEnabled'"></mat-icon>
                {{ documentAssignment.companyDocument.name }}
            </div>

            <div class="status columns-4">
                <ui-pill
                    type="primary"
                    *ngIf="documentAssignment.isSigningDocument() && documentAssignment.hasTemplateBeenEdited()"
                    >{{ 'forms.applicant-tracker.onboarding-documents.edited' | appTranslate }}
                </ui-pill>
                <mat-icon svgIcon="close" (click)="onRemoveDocumentAssignment(documentAssignment)"></mat-icon>
            </div>
        </ng-template>
        <div class="ui divider"></div>
    </div>

    <ui-button type="link" (click)="onSelectDocuments()"
        ><mat-icon svgIcon="plus"></mat-icon
        >{{ 'forms.applicant-tracker.onboarding-documents.addDocuments' | appTranslate }}</ui-button
    >

    <!-- Hidden element to check all documents have signer role assignments -->
    <input
        type="hidden"
        name="allDocumentAssignedCheck"
        [(ngModel)]="allDocumentAssignmentValid"
        [required]="documentAssignments.length > 0 && hasAllDocumentAssigned === false"
        #allDocumentAssigned="ngModel"
    />
</form>
