import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { BenefitPlan as EmployeeBenefitPlan } from '@models/employee/benefits/benefit-plan.model';

@Component({
    selector: 'app-form-benefits',
    templateUrl: './benefits.template.html',
})
export class BenefitsForm extends BaseForm {
    @Input() employeeBenefitPlan: EmployeeBenefitPlan;
    @Input() benefitPlans: BenefitPlan[];

    showBenefitFields = false;

    beforeShow() {
        this.setShowBenefitFields();
    }

    private setShowBenefitFields(): void {
        if (this.employeeBenefitPlan?.benefitPlan?.hasEnhancedManagedBenefits) {
            this.showBenefitFields = false;
            return;
        }

        this.showBenefitFields = true;
    }
}
