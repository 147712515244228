<form #form="ngForm">
    <ng-container *ngIf="employeeBenefitPlan">
        <div class="app-grid">
            <ng-container *ngIf="showBenefitFields">
                <ng-container *ngIf="benefitPlans">
                    <mat-form-field class="columns-12">
                        <mat-label appRemoveAriaOwns>Plan Class</mat-label>
                        <mat-select name="plan_id" [(ngModel)]="employeeBenefitPlan.benefitPlanId">
                            <mat-option [value]="null">None</mat-option>
                            <mat-option *ngFor="let plan of benefitPlans" [value]="plan.id"
                                >{{ plan.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>Eligible</mat-label>
                    <mat-select name="eligible" [(ngModel)]="employeeBenefitPlan.isEligible">
                        <mat-option value="">Select an option...</mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>Enrolled</mat-label>
                    <mat-select name="enrolled" [(ngModel)]="employeeBenefitPlan.isEnrolled">
                        <mat-option value="">Select an option...</mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>Waiting Period Waived</mat-label>
                    <mat-select name="waiting_period_waived" [(ngModel)]="employeeBenefitPlan.isWaitingPeriodWaved">
                        <mat-option value="">Select an option...</mat-option>
                        <mat-option [value]="true">Yes</mat-option>
                        <mat-option [value]="false">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>Dependent Status</mat-label>
                    <mat-select name="opted_as_type" [(ngModel)]="employeeBenefitPlan.isFamily">
                        <mat-option [value]="false">Single</mat-option>
                        <mat-option [value]="true">Family</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <h2 class="ui left floated header">Benefits Card</h2>

            <mat-form-field class="columns-12">
                <mat-label>Card Title 1</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_1"
                    [(ngModel)]="employeeBenefitPlan.cardTitlePrimary"
                    placeholder="Eg: Policy Number"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>Card Value 1</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_1"
                    [(ngModel)]="employeeBenefitPlan.cardValuePrimary"
                    placeholder="Eg: 493094"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>Card Title 2</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_2"
                    [(ngModel)]="employeeBenefitPlan.cardTitleSecondary"
                    placeholder="Eg: Employee ID"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>Card Value 2</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_2"
                    [(ngModel)]="employeeBenefitPlan.cardValueSecondary"
                    placeholder="Eg: 0000001"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>Card Title 3</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_title_3"
                    [(ngModel)]="employeeBenefitPlan.cardTitleTertiary"
                    placeholder="Eg: Carrier ID"
                />
            </mat-form-field>
            <mat-form-field class="columns-12">
                <mat-label>Card Value 3</mat-label>
                <input
                    matInput
                    type="text"
                    name="card_value_3"
                    [(ngModel)]="employeeBenefitPlan.cardValueTertiary"
                    placeholder="Eg: 81"
                />
            </mat-form-field>
        </div>
    </ng-container>
</form>
